import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import { isNullOrUndefined } from "util"

export default ({ data }) => {
  const post = data.markdownRemark
  return (
    <Layout>
      <div>
        <h1>{post.frontmatter.title}</h1>
        <h2>{post.frontmatter.date}</h2>
        {!isNullOrUndefined(post.frontmatter.dates) &&
          post.frontmatter.dates.map((value) => {
            return <h2>{value}</h2>
          })
        }
        <p>Location: {post.frontmatter.location}</p>
        <div dangerouslySetInnerHTML={{ __html: post.html }} />
      </div>
    </Layout>
  )
}

export const query = graphql`
  query($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        date(formatString: "dddd, MMMM Do YYYY, h:mma")
        dates(formatString: "dddd, MMMM Do YYYY, h:mma")
        location
      }
    }
  }
`